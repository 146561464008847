.row {
  width: 100%;
  /* min-height: 100px; */
  display: flex;
  color: #a9abb0;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5%;
  width: 150px;
  height: 150px;
  margin: 25px;
  padding: 10px;
  box-shadow: 10px 10px 29px 3px rgba(168, 168, 168, 0.24);
  background-color: white;
}

.itemNoShadow {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5%;
  width: 120px;
  height: 120px;
  margin: 25px;
  padding: 10px;
}

@media (max-width: 620px) {
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5%;
    width: 15vw;
    height: 15vw;
    margin: 3vw;
    padding: 1.5vw;
    box-shadow: none;
    background-color: white;
  }

  .itemNoShadow {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5%;
    width: 15vw;
    height: 15vw;
    margin: 15px;
    padding: 10px;
  }
}

.img {
  width: 100%;
  height: auto;
}
