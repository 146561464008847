.headerDesktop {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 3;
  background-color: white;
  border-bottom: 0.5px solid #dddee0;
}

.iconDesktop {
  padding-left: 2vw;
  box-sizing: content-box;
  display: flex;
  width: 30px;
  height: 30px;
}

.headerMobile {
  position: fixed;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  border-bottom: 0.5px solid #dddee0;
}

.iconMobile {
  padding-left: 25px;
  /* padding-right: 25px; */
  padding-top: 4px;
  box-sizing: content-box;
}

.menu {
  padding-left: 25px;
  padding-right: 25px;
}

.button {
  display: flex;
  padding-right: 2vw;
}

.buttonForm {
  min-height: 48px;
  max-height: 85px;
  min-width: 220px;
  background: #d4f5ed;
  border: 2px solid #d4f5ed;
  border-radius: 8px;
  margin-right: 10px;
  font-family: Heebo, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: #03afa7;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .buttonForm {
    height: 48px;
  }
}
