.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: normal;
}

.button {
  color: white;
  font-weight: bold;
  font-size: 1rem;
  padding: 12px 20px;
  min-height: 48px;
  width: inherit;
  max-height: 85px;
  min-width: 220px;
  width: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #03afa7;
  border-radius: 8px;
  border-color: transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.buttonDesktop:active {
  filter: brightness(105%);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinner {
  right: 0;
  margin-left: 5px;
  animation: rotation 2s infinite linear;
}

.text {
  padding-top: 10px;
}

.a {
  cursor: pointer;
}
