.descriptionBox {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 640px;
  letter-spacing: 0.04em;
  text-align: left;
  font-family: Poppins, sans-serif;
  z-index: 2;
  height: auto;
}

.h1 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 130%;
  /* or 62px */
  letter-spacing: 0.02em;
}
