.footerCaseUUID {
  font-weight: bold;
}

.footerMiddleContainer {
  display: flex;
  flex-direction: row;
}

.footerWrapper {
  display: flex;
  flex-direction: column;
  background-color: #fafbfc;
  color: #4b4f55;
  font-size: 11px;
  line-height: 14px;
  font-family: Heebo, sans-serif;
  padding: 24px 65px;
}

.footerBlock {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.footerWrapper a {
  color: #4b4f55;
}

.footerWrapper a:hover {
  color: #0a0d1b;
  text-decoration: underline;
}

@media screen and (min-width: 900px) {
  .footerWrapper {
    flex-direction: row;
    justify-content: space-between;
  }
  .footerBlock {
    margin: 0;
  }
}
