.row {
  display: flex;
  align-items: center;
}

.img {
  padding: 0 5px;
  width: 25px;
  height: 25px;
}

@media (max-width: 620px) {
  .img {
    width: 45px;
    height: 45px;
    padding: 0 5px;
  }
}
