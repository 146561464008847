body {
  font-size: 18px;
  height: 100%;
  margin: 0;
  font-display: swap;
  font-family: Heebo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: #4b4f55;
}

h1,
h2,
h3 {
  color: #0a0d1b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:focus {
  outline: transparent;
}

.main {
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 75px;
  padding-bottom: 65px;
  padding-left: 2vw;
  padding-right: 2vw;
}
@media (max-width: 620px) {
  .main {
    padding-top: 65px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
  }

  p {
    text-align: center;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main {
    /* HACK FOR IE11 bottom space */
    padding-bottom: 165px;
  }
}

.root {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  width: 100%;
  height: 75px;
  z-index: 1;
  display: flex;

  justify-content: space-between;
  align-items: center;

  background-color: white;
}

@media (max-width: 620px) {
  header {
    max-height: 75px;
  }
}

img.badge {
  padding-right: 15px;
}

a {
  display: flex;
  color: #a9abb0;
  align-items: center;
  text-decoration: none;
}

strong {
  color: #03afa7;
  font-weight: 600;
}

h1,
h2,
h3,
h4 {
  text-align: start;
  font-family: Poppins, sans-serif;
}

h1 {
  font-size: 48px;
  line-height: 130%;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  margin-top: 20px;
}

span {
  text-align: left;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.inactive {
  color: grey;
}

.active {
  color: #11c6b0;
}

.stepNumber {
  border-radius: 100%;
  z-index: 2;
  transform: translateY(1rem) translateX(2rem);
  font-size: 1.5rem;
  font-weight: bold;
  width: 1.5rem;
  height: 1.5rem;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 30vh;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.blackBox {
  width: 100vw;
  flex-grow: 1;
  background-color: #0a0d1b;
  color: #fff;
}

.blackBoxInner {
  height: 100%;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px;
}

.blackBoxInner > p {
  margin: 15px 0;
  font-weight: 400;
}

.blackBoxInner > h1,
h4 {
  margin: 20px 0;
  font-weight: 400;
}

.strikeout {
  margin: 0.5rem 0;
  text-align: left;
  display: flex;
  text-decoration-line: line-through;
  text-decoration-color: #595959;
  text-decoration: line-through;
}

.mockup {
  max-width: 900px;
  min-width: 400px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

footer {
  font-size: 1rem;
}

div .countrySelect span {
  background-color: transparent;
}

div .countrySelectNew {
  border: 1px solid #dddee0;
  border-radius: 4px;
  margin-right: 8px;
}
div .countrySelectNew span {
  background-color: transparent;
}

p {
  font-family: Heebo, sans-serif;
}

/* hack to fix some ie11 related react-select issues */
.css-1hwfws3 {
  flex: 1 !important;
  margin-right: 0 !important;
  padding-right: 0 !important;
}

/* **************************** NEW JUMP PAGE EXPERIMENT **************************** */
.newPageRoot {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.newPageMessageBanner {
  background-color: #fff2f3;
  color: #f01c4f;
  font-family: 'Heebo', sans-serif;
  font-weight: normal;
  text-align: center;
  padding: 8px 16px;
  border-radius: 4px;
}

.newPageMessageBanner-blue {
  background-color: #f2f9ff;
  color: #0a6cff;
}

.newPageWrapper {
  width: 100%;
  max-width: 900px;
  padding: 0 40px;
  margin: 40px auto;
  text-align: center;
}

.newPageHeaderLogos {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newPageHeaderTitle {
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.03em;
  text-align: center;
  margin: 21px 0 16px;
}

.newPageHeaderSubtitle {
  font-family: 'Heebo', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  max-width: 580px;
  margin: auto;
  color: #4b4f55;
}
.newPageHeaderSubtitle b {
  font-weight: 700;
}

.newPageCaseStartWrapper {
  padding: 32px 0;
  display: flex;
}

.newPageCaseStartQR,
.newPageCaseStartSMS {
  flex: 1;
  text-align: left;
  padding-bottom: 15px;
}
.newPageCaseStartQRTitle,
.newPageCaseStartSMSTitle {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
}
.newPageCaseStartQRTitle > p,
.newPageCaseStartSMSTitle > p {
  color: #4b4f55;
  font-family: 'Heebo', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.newPageCaseStartLine {
  display: flex;
  margin: 0 20px;
}
.newPageCaseStartLineVL {
  border-left: 1px solid #a9abb0;
  margin-left: 30px;
  width: 30px;
  position: relative;
  padding: 20px 0;
}
.newPageCaseStartLabel {
  color: #a9abb0;
  background-color: #fff;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
  padding: 2px 0;
  position: absolute;
  width: 60px;
  left: -30px;
}

@media screen and (min-width: 750px) {
  .newPageCaseStartLine {
    display: flex;
    margin: 0 40px;
  }
}
@media screen and (min-width: 900px) {
  .newPageCaseStartLine {
    display: flex;
    margin: 0 100px;
  }
}
/* ************************** END NEW JUMP PAGE EXPERIMENT ************************** */
