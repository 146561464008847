.box {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
}

.img {
  padding-bottom: 10px;
}

.bigNumber {
  font-size: 1.75rem;
  line-height: 1.5rem;
}

.star {
  width: 4vw;
  max-width: 22px;
}
