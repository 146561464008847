.footerLinks {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
}

.mobileColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
}

.column {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  flex-direction: column;
}

.aMobile {
  color: #0a0d1b;
  font-size: 1.5rem;
  font-family: Heebo, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  line-height: 4rem;
}
