.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: normal;
  padding: 25px 0;
}

.button {
  color: white;
  font-weight: bold;
  font-size: 1rem;
  height: 48px;
  width: 272px;
  max-width: 80vw;
  left: 0px;
  right: 0px;
  top: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #03afa7;
  border-radius: 8px;
  border-color: transparent;
}

.buttonDesktop:active {
  filter: brightness(105%);
}

/* .buttonMobile {
  height: 48px;
  width: 272px;
  left: 0px;
  right: 0px;
  top: 0px;

  background: #03AFA7;
  border-radius: 8px;
} */

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinner {
  right: 0;
  margin-left: 5px;
  animation: rotation 2s infinite linear;
}

.text {
  padding-top: 10px;
}
