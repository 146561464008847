.badge {
  display: flex;
  height: 45px;
}

@media (max-width: 620px) {
  .badge {
    display: flex;
    justify-content: center;
    width: 200px;
    color: #0a0d1b;
    text-decoration: underline;
    font-weight: 800;
    font-family: Heebo, sans-serif;
  }
}

@media (max-width: 620px) {
  .button {
    max-width: inherit;
  }
}

.button {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  padding: 13px 50px;
  width: 100%;
  max-width: 275px;
  font-size: 1rem;
  left: 0px;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  text-align: center;

  background: #03afa7;
  border-radius: 8px;
  border-color: transparent;
  box-sizing: content-box;
}
