.row {
  min-height: 35px;
  display: flex;
  font-weight: bold;
  flex-direction: row;
  color: #a9abb0;
  font-size: 1rem;
  align-items: center;
  padding-bottom: 15px;
}

.backBoxMobile {
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 400;
  color: #dddee0;
}

.icon {
  padding-right: 5px;
}

.logo {
  height: 65px;
  margin: 5px 0;
}

.mobileLogo {
  padding-right: 20px;
  max-width: 150px;
  max-height: 50px;
}
