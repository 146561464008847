@media (min-width: 620px) {
  .actionBox {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-self: center;
  }
}

@media (min-width: 1000px) {
  .actionBox {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-self: center;
  }
}

.h1 {
  margin: 0;
  font-size: 2rem;
  font-style: normal;
  line-height: 60px;
  font-family: Heebo, sans-serif;
  font-weight: 900;
}

p {
  font-size: 1rem;
  margin: 10px 0;
}
