.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  height: 25px;
  width: 25px;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid #000;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
  width: 260px;
  max-height: 120px;
  height: 100px;
  min-height: 100px;
  margin: 1vw;
  padding: 10px;
  font-size: 0.85rem;
}

@media (min-width: 900px) {
  .item {
    max-height: 120px;
    height: 100px;
    min-height: 100px;
  }
}

@media (max-width: 620px) {
  .item {
    width: 80vw;
    font-size: 1rem;
    box-shadow: inherit;
    border-radius: 2vw;
    max-height: 100px;
    height: 100px;
    min-height: 80px;
  }
}
