.fullscreen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 3;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
}

.header {
  position: fixed;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  border-bottom: 1px solid #dddee0;
}
