.stateBox {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.textStepBox {
  padding: 1vw 2vw;
  border: 1px solid grey;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 30vw;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
}
